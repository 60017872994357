<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { getRegisterOverview, getRegisterOverviewFilterOptions } from "@/services/api/energy.api";
import GridPagination from "@/components/general/GridPagination.vue";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import useGrid27 from "@/composables/grid27.js";
import { FilterDataName, mapFilterResult, FilterStatus, mapFilterResponseStatus } from "@/stores/filterdata";

const emits = defineEmits(["selected-register"]);

const { formatDateUtc } = useDateTimeFormatter();

const { fetchFirstPage, gridData, gridOptions, gridIsLoading, pageNumber, pageSize, pageCount, totalItems, changedOptions, changedPaging } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
});

pageSize.value = 25;

const props = defineProps({
  isSelector: {
    type: Boolean,
    default: false,
  },
});

const filterData = ref([]);

const _headers = [
  { text: "", value: "actions", sortable: false, width: 20, showAsSelector: false },
  { text: "", value: "select", sortable: false, showAsSelector: true },
  { text: "Referentie", value: "reference" },
  { text: "Omschrijving", value: "description" },
  { text: "Type", value: "typeDescription" },
  { text: "Energietype", value: "energyTypeDescription" },
  { text: "Eenheid", value: "unitTypeDescription" },
  { text: "Indicatief?", value: "isIndicative" },
  { text: "Bron", value: "source" },
  { text: "Bronreferentie", value: "sourceReference" },
  { text: "Geldig van", value: "valFrom" },
  { text: "Geldig tot", value: "valTill" },
];

const headers = computed(() => {
  const newHeaders = _headers.filter((x) => x.showAsSelector === undefined || x.showAsSelector == props.isSelector);
  return newHeaders;
});

const filters = ref([
  {
    name: "Reference",
    fieldName: "reference",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Omschrijving",
    fieldName: "description",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Type",
    fieldName: "typeDescription",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Energietype",
    fieldName: "energyTypeDescription",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Eenheid",
    fieldName: "unitTypeDescription",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Indicatief",
    fieldName: "isIndicative",
    type: "list",
    options: [
      { id: "true", text: "Ja" },
      { id: "false", text: "Nee" },
    ],
  },
  {
    name: "Bron",
    fieldName: "source",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Bronreferentie",
    fieldName: "sourceReference",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
]);

watch(
  () => filterData.value,
  () => {
    fetchFirstPage();
  },
  { deep: true }
);

async function getData(pageNumber, pageSize, sortBy, sortDesc) {
  const pageNumber0 = pageNumber - 1;
  const response = await getRegisterOverview(pageNumber0, pageSize, sortBy, sortDesc, filterData.value);
  return response;
}

function selectedRegister(registerId) {
  emits("selected-register", registerId);
}

async function handleFilterSearch(index, text) {
  console.log("RegisterOverviewGrid handleFilterSearch: ", filters.value[index].fieldName, text);
  let result = [];
  let status = FilterStatus.None;
  switch (filters.value[index].fieldName) {
    case "isInd":
      result = filters.value[index].options;
      break;

    default:
      if (text && text.length > 0) {
        const response = await getRegisterOverviewFilterOptions(filters.value[index].fieldName, text);
        console.log("RegisterOverviewGrid handleFilterSearch response: ", response);
        status = mapFilterResponseStatus(response.severity, response.code);
        if (status === FilterStatus.Success) {
          result = response.data.data;
        } else {
          if (status === FilterStatus.Error) {
            proxy.$toaster.error("Ophalen van de data is mislukt! " + response.message);
          }
        }
      }
      break;
  }
  filters.value[index].status = status;
  filters.value[index].options = mapFilterResult(result);
}

function handleFiltersChanged(newFilterData) {
  console.log("RegisterOverviewGrid handleFiltersChanged filterData: ", newFilterData);
  filterData.value = [...newFilterData];
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}
</script>

<template>
  <div>
    <v-row class="overview-filters align-items-start">
      <v-col cols="auto">
        <filter-set :name="FilterDataName.Meters" :filters="filters" @filter-search="handleFilterSearch" @filters-changed27="handleFiltersChanged"></filter-set>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :options.sync="gridOptions"
      :headers="headers"
      hide-default-footer
      :items="gridData"
      @update:options="changedOptions"
      :loading="gridIsLoading"
      :items-per-page="pageSize"
      :server-items-length="totalItems"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn :to="{ name: 'EU-Register', params: { registerId: item.id.toString(), addModus: false } }" icon small plain>
          <v-icon small> mdi-table </v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.select`]="{ item }">
        <v-btn dark class="primary my-1" title="Kiezen" @click="selectedRegister(item.id)">
          <v-icon dark left>mdi-checkbox-marked-circle-outline</v-icon>
          Kiezen
        </v-btn>
      </template>

      <template v-slot:[`item.isIndicative`]="{ item }">
        <v-layout wrap justify-center>
          <v-checkbox class="ma-0 pa-0 center" disabled v-model="item.isIndicative" hide-details></v-checkbox>
        </v-layout>
      </template>

      <template v-slot:[`item.valFrom`]="{ item }">
        {{ formatDateUtc(item.valFrom) }}
      </template>

      <template v-slot:[`item.valTill`]="{ item }">
        {{ formatDateUtc(item.valTill) }}
      </template>
    </v-data-table>
    <grid-pagination :pageNumber="pageNumber" :pageSize="pageSize" :pageCount="pageCount" @changedPaging="changedPaging"></grid-pagination>
  </div>
</template>
